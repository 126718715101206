$location: (ml: margin-left, mt: margin-top, mr: margin-right, mb: margin-bottom, mg: margin, pl: padding-left, pt: padding-top, pr: padding-right, pb: padding-bottom, pd: padding);
@for $i from 0 through 10 {
    @each $a,
    $b in $location {
        .#{$a}#{5 * $i} {
            #{$b}: 5px * $i!important;
        }
    }
}

@for $i from 6 through 18 {
    .f#{2 * $i} {
        font-size: 2px * $i!important;
    }
}

@for $i from 0 through 15 {
    .lh#{$i} {
        line-height: 1 + $i / 10!important;
    }
}

@for $i from 0 through 5 {
    .br#{$i} {
        border-radius: $i!important;
    }
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.clear {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

.clearfix:after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.container {
    margin-right: auto;
    margin-left: auto;
}

.container:after,
.row:after,
.center,
ul {
    @extend .clearfix;
}

.content img {
    max-width: 100%;
}

.content_box {
    &,
    *,
    *:before,
    *:after {
        box-sizing: content-box;
    }
}

.tal {
    text-align: left!important;
}

.tac {
    text-align: center!important;
}

.tar {
    text-align: right!important;
}

.vat {
    vertical-align: top!important;
}

.vam {
    vertical-align: middle!important;
}

.vab {
    vertical-align: bottom!important;
}

.block {
    display: block!important;
}

.iblock {
    @include inline-block;
}

.posr {
    position: relative;
}

.posa {
    position: absolute;
}

.posf {
    position: fixed;
}

.ofh {
    overflow: hidden;
}

.ofv {
    overflow: visible;
}

::-webkit-input-placeholder {
    color: #b6b6b6;
}
:-moz-placeholder {
    color: #b6b6b6;
}
::-moz-placeholder {
    color: #b6b6b6;
}
:-ms-input-placeholder {
    color: #b6b6b6;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-thumb {
    background-color: $color;
}
::-webkit-scrollbar-thumb:hover {
    background-color: darken($color, 10%);
}
::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}
