/****************************** components ******************************/
@import "./components/normalize"; // 样式标准化
@import "./components/color"; // 颜色
@import "./components/mixin"; // 混合
@import "./components/reset"; // 样式重置
@import "./components/default"; // 默认样式
/****************************** style ******************************/
body {
    background-color: #eceff4;
}
.header {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 80px;
    background-color: $color;
    padding-left: 30px;
    padding-right: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ul {
        display: flex;
        li {
            margin-left: 30px;
        }
    }
}
.aside {
    position: absolute;
    z-index: 99;
    left: 0;
    top: 80px;
    bottom: 0;
    width: 258px;
    background-color: #fff;
    overflow-x: hidden;
    padding-top: 35px;
    padding-left: 15px;
    >ul {
        >li {
            margin-bottom: 10px;
            >a {
                display: block;
                width: 231px;
                height: 43px;
                line-height: 43px;
                border-radius: 21.5px;
                padding: 0 30px 0 55px;
                transition: none;
                position: relative;
                color: $c_666;
                &:hover,&.active {
                    color: #fff;
                    background-color: $color;
                    @for $i from 1 through 10 {
                        .icon#{$i} {
                            background-image: url(../../Public/img/aside_icon#{$i}_h.png);
                        }
                    }
                }
                &.arrow.active:before {
                    transform: rotate(180deg);
                }
                @for $i from 1 through 10 {
                    .icon#{$i} {
                        background-image: url(../../Public/img/aside_icon#{$i}.png);
                    }
                }
                [class^="icon"] {
                    position: absolute;
                    width: 50px;
                    height: 100%;
                    left: 5px;
                    top: 0;
                    background-repeat: no-repeat;
                    background-position: center;
                }
                &.arrow:before {
                    content: "";
                    position: absolute;
                    width: 11px;
                    height: 6px;
                    right: 15px;
                    top: 50%;
                    margin-top: -3px;
                    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAGCAYAAAAVMmT4AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjIxQUI0RTgxNkM0QzExRTc4MkM5RkM4MkVGREFGMUU5IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjIxQUI0RTgyNkM0QzExRTc4MkM5RkM4MkVGREFGMUU5Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MjFBQjRFN0Y2QzRDMTFFNzgyQzlGQzgyRUZEQUYxRTkiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MjFBQjRFODA2QzRDMTFFNzgyQzlGQzgyRUZEQUYxRTkiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4Z3c8kAAAAcElEQVR42mL8////LQYGhgQgPsaAG8wB4i8MQMWhQPwHiA2AmAENswDxXJCBQMwMEwwA4n9A7IimeB0QXwRidhAfWcIfiH8BsRqUvxKIzwIxJ0wNurXRQPwbiA8C8Ul0ZzFgcWcgEO8EYm50OYAAAwCGmLfhGNFe7QAAAABJRU5ErkJggg==) no-repeat center;
                    transition: transform .2s;
                }
            }
            ul {
                display: none;
                padding: 10px 0;
                li {
                    padding: 5px 30px 5px 60px;
                    a {
                        color: $c_666;
                        font-size: 14px;
                        &:hover,&.active {
                            color: $color;
                        }
                    }
                }
            }
        }
    }
}
.layui-side {
    position: absolute;
    z-index: 99;
    left: 0;
    top: 80px;
    bottom: 0;
    width: 258px;
    background-color: #fff;
    overflow-x: hidden;
    .layui-side-scroll {
        width: 278px;
    }
    .layui-nav-tree {
        width: 258px;
    }
    .layui-nav {
        background-color: #fff;
        color: #666;
        .layui-nav-item {
            a {
                color: #333!important;
                position: relative;
                height: 56px;
                line-height: 56px;
                padding-left: 65px;
                padding-right: 30px;
                &:hover {
                    background-color: #f8f8f8;
                    color: $color!important;
                }
                img {
                    position: absolute;
                    left: 25px;
                    top: 50%;
                    margin-top: -10px;
                }
            }
            .layui-nav-child {
                background-color: #fff!important;
            }
        }
        .layui-this {
            color: $color!important;
            background-color: #f8f8f8!important;
            >a {
                color: $color!important;
                background-color: #f8f8f8!important;
            }
        }
        .layui-nav-more {
            border-top-color: #333;
        }
        .layui-nav-itemed {
            >a {
                .layui-nav-more {
                    border-color: transparent transparent #333;
                }
            }
        }
        .layui-nav-bar {
            left: auto;
            right: 0;
            background-color: $color;
        }
        .layui-nav-child {
            .layui-nav-child {
                a {
                    padding-left: 80px;
                }
            }
        }
    }
}
.section {
    position: absolute;
    z-index: 9;
    left: 275px;
    right: 0;
    top: 80px;
    bottom: 0;
    background-color: #fff;
    overflow-x: hidden;
    padding: 20px 80px 0 35px;
}

.notice {
    border: 1px solid #efefef;
    background-color: #fefff6;
    padding: 10px 20px;
    color: #000;
    line-height: 1.75;
}
.progress {
    margin-left: 110px;
    width: 323px;
    margin-top: 10px;
}
.btn {
    min-width: 80px;
    background-color: $color2;
}
.color_btn {
    background-color: $color2;
}
.layui-btn-xs {
    min-width: 53px;
}
.btn_red {
    background-color: #f00;
}
.btn_green {
    background-color: #00de4b;
}
.btn_blue {
    background-color: #5f80ce;
}
.btn_gray {
    background-color: #f2f2f2;
    color: #666!important;
}
.table_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.search {
    display: flex;
    input {
        width: 183px;
        height: 38px;
        line-height: 36px;
        border: 1px solid #e6e6e6;
        border-right: 0;
        border-radius: 0;
        padding: 0 10px;
        @include placeholder(#e6e6e6);
    }
}
.weight {
    display: inline-block;
    width: 55px;
    height: 22px;
    background: url(../../Public/img/weight_bg.png) no-repeat center;
    line-height: 22px;
    padding-left: 36.5px;
    color: #fff;
    text-align: left;
}
.table_progress {
    position: absolute;
    width: 80%;
    left: 10%;
    top: 50%;
    margin-top: -12px;
    &,.layui-progress-bar {
        border-radius: 3px;
        height: 24px;
        line-height: 24px;
    }
    .layui-progress-bar {
        background-color: $color2;
    }
    .layui-progress-text {
        line-height: 24px;
    }
}
.release_tit {
    width: 100%;
    height: 40px;
    line-height: 38px;
    border: 1px solid #ececec;
    color: #fff;
    background-color: $color2;
    padding: 0 25px;
    .layui-colla-icon {
        display: none;
    }
}
.release_flex {
    display: flex;
    justify-content: space-between;
    .items {
        flex: 1;
        margin-left: 25px;
        &:first-child {
            margin-left: 0;
        }
        .release_tit {
            margin-bottom: -1px;
        }
    }
    .layui-textarea {
        height: 375px;
    }
}
.maintain {
    .layui-tab-title {
        margin-bottom: 20px;
        li {
            color: $color2;
            &.layui-this {
                color: #666;
            }
        }
    }
    .layui-tab-content {
        padding: 0;
    }
    .layui-colla-item {
        margin-bottom: 30px;
    }
    .layui-collapse {
        border: 0;
    }
    .layui-colla-content {
        padding: 0;
        border: 0;
    }
}
.diqu_checkbox {
    display: inline-block;
    vertical-align: middle;
    height: auto!important;
    line-height: normal!important;
    border: none!important;
    margin-right: 0;
    padding-right: 0;
    background: 0 0;
    position: relative;
    cursor: pointer;
    font-size: 0;
    border-radius: 2px;
    transition: .1s linear;
    &:hover {
        span {
            background-color: transparent;
        }
        i {
            border-color: $color2;
            color: transparent;
        }
    }
    input {
        display: none!important;
        &:checked + i {
            border-color: $color2;
            background-color: $color2;
            color: #fff;
            font-size: 12px;
        }
    }
    span {
        float: right;
        line-height: 18px;
        background: 0 0;
        color: #666;
        padding: 0 10px;
        height: 100%;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    i {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: 0;
        right: 0;
        width: 16px;
        line-height: 16px;
        border: 1px solid #d2d2d2;
        font-size: 0;
        border-radius: 2px;
        background-color: #fff;
        transition: .1s linear;
        text-align: center;
        color: transparent;
    }
}
.material_img {
    li {
        float: left;
        margin-right: 36px;
        margin-bottom: 42px;
        position: relative;
    }
    .diqu_checkbox {
        position: absolute;
        left: 10px;
        top: 10px;
        z-index: 9;
    }
    .img_box {
        width: 209px;
        height: 209px;
        margin-bottom: 11px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-color: #eee;
        cursor: pointer;
    }
    img {
        display: none;
    }
    .del {
        float: right;
        width: 66px;
        height: 33px;
        line-height: 33px;
        color: #fff;
        font-size: 12px;
        border-radius: 1px;
        background-color: $color2;
        text-align: center;
        i {
            margin-right: 5px;
        }
    }
}
.pages {
    a,span {
        @include inline-block;
        min-width: 1.5em;
        text-align: center;
        border: 1px solid transparent;
        padding: 7px 14.5px;
        border-color: #e3e3e3;
        margin-left: 8px;
        border-radius: 5px;
        @include hover(#333,color,color,#fff);
        @include hover(transparent,background-color,color,$color2);
    }
    .current {
        color: #fff;
        background-color: $color2;
    }
}
.CodeMirror {
    border: 1px solid #ddd;
    .error {
        background-color: #ffffe0;
        .CodeMirror-linenumber {
            color: red;
        }
    }
    .CodeMirror-scroll pre {
        font-family: "微软雅黑";
    }
}
#collect_form {
    .layui-form-label {
        padding-left: 0;
        width: 100px;
    }
    .layui-input-block {
        margin-left: 100px;
    }
    .submit {
        position: absolute;
        left: 100%;
        top: 0;
        z-index: 9;
    }
    .layui-form-item .layui-input-inline {
        max-width: 100%;
    }
    .collect_type_item .layui-input-inline {
        width: 180px;
        margin-right: 0;
    }
    .collect_type_item .layui-inline:last-child {
        margin-right: 0;
    }
}
.input_aux {
    position: absolute;
    top: 0;
    right: 10px;
    height: 38px;
    line-height: 38px;
}

body {
    .msg_success, .msg_error {
        filter: alpha(opacity=90);
        color: #fff;
        border: none;
        box-shadow: 1px 1px 8px #666;
        .layui-layer-content {
            font-size: 16px;
            text-align: left;
            padding-left: 50px;
            background-repeat: no-repeat;
            background-position: 15px center;
        }
    }
    .msg_success {
        background-color: #51a351;
        background-color: rgba(81,163,81,.9);
        .layui-layer-content {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==);
        }
    }
    .msg_error {
        background-color: #bd362f;
        background-color: rgba(189,54,47,.9);
        .layui-layer-content {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=);
        }
    }
    .msg_loading {
        .layui-layer-content {
            background-image: url(../../Public/img/loading.gif);
            width: 40px;
            height: 40px;
        }
    }
    .layui-form-item .layui-input-inline {
        width: 243px;
        &.w334 {
            width: 334px;
        }
    }
    .layui-form-label {
        color: $c_666;
        width: 110px;
    }
    .layui-form-radio i:hover, .layui-form-radioed i {
        color: $color2;
    }
    .layui-form-checkbox[lay-skin=primary]:hover i,.layui-form-checked[lay-skin=primary] i {
        border-color: $color2;
    }
    .layui-btn.layui-btn-normal,.layui-form-select dl dd.layui-this,.layui-form-checked[lay-skin=primary] i {
        background-color: $color2;
    }
    .layui-input:disabled {
        background-color: rgb(235, 235, 228);
        color: rgb(84, 84, 84);
    }
    .layui-form-onswitch {
        border-color: $color2;
        background-color: $color2;
    }
    .layui-table-view {
        th,td {
            text-align: center;
        }
        th {
            background-color: #f4f8fd;
        }
    }
    .layui-laypage .layui-laypage-curr .layui-laypage-em {
        background-color: $color2;
    }
    .layui-table-init {
        background-color: rgba(0,0,0,.1);
        .layui-icon {
            width: 40px;
            height: 40px;
            background: url(../../Public/img/loading.gif) no-repeat center;
            margin: -20px 0 0 -20px;
            &:before {
                display: none;
            }
        }
    }
    .layui-table-edit {
        &:focus {
            border-color: $color!important;
        }
    }
    .layui-table-cell {
        height: 42px;
        line-height: 42px;
    }
    .layui-table-tips-c {
        width: 26px;
        height: 26px;
        line-height: 22px;
    }
    .layui-layer-tips .layui-layer-content {
        line-height: 36px;
    }
    .layui-form-text {
        margin-right: 68px;
        .layui-textarea {
            min-height: 163px;
        }
    }
    .layui-laydate .layui-this {
        background-color: $color2!important;
    }
    .layui-form-switch {
        box-sizing: content-box;
    }
    .disabled_color {
        .layui-disabled, .layui-disabled:hover {
            color: #333!important;
        }
        .layui-radio-disbaled>i {
            color: #c2c2c2!important;
        }
        .layui-form-radio.layui-radio-disbaled>i:hover, .layui-form-radioed.layui-radio-disbaled>i {
            color: $color2!important;
        }
    }
}
.index_box {
    width: 980px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &:after {
        display: none;
    }
    li {
        margin-bottom: 30px;
        width: 220px;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #f2f2f2;
        span {
            color: #4381e6;
            font-size: 30px;
        }
        p {
            font-size: 16px;
            color: #666;
        }
    }
}

.word_pack_template {
    position: relative;
    height: 100%;
    padding-top: 154px!important;
    .word_pack_fixed {
        position: absolute;
        z-index: 999;
        width: 100%;
        left: 0;
        top: 0;
        padding: 20px;
        background-color: #fff;
    }
    .word_pack_combination {
        height: 100%;
        overflow-x: hidden;
    }
    .items_tit {
        border-bottom: 1px solid #efefef;
        margin-bottom: 10px;
        padding-bottom: 5px;
        span + span {
            color: #bdbdbd;
            padding-left: 10px;
        }
        i {
            font-style: normal;
        }
    }
    .items {
        margin-bottom: 15px;
        li {
            width: 25%;
            float: left;
        }
    }
    .layui-form-checkbox {
        margin-bottom: 10px;
    }
}

.oem_menu {
    display: flex;
    justify-content: space-around;
    border-top: 0;
    margin-bottom: 10px;
    .item {
        flex: 1;
        height: 155px;
        margin-left: 10px;
        margin-bottom: 10px;
        padding: 10px 40px;
        color: #fff;
        position: relative;
        &:after {
            content: '';
            width: 45px;
            height: 45px;
            background-position: center;
            background-repeat: no-repeat;
            position: absolute;
            right: 40px;
            top: 50%;
            margin-top: -22px;
        }
        &:first-child {
            margin-left: 0;
        }
        .small_btn {
            display: inline-block;
            width: 53px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            font-size: 12px;
            color: #fff;
            background-color: rgba(255,255,255,.5);
            transition: background-color .3s;
            cursor: pointer;
            &:hover {
                background-color: rgba(255,255,255,.6);
            }
        }
        dl {
            line-height: 2;
            min-height: 92px;
            margin-bottom: 5px;
        }
        dt {
            font-size: 18px;
        }
        dd {
            font-size: 24px;
        }
    }
    .oem1-1 {
        background: url(../img/oem/1_1.png) no-repeat right 40px bottom -15px, linear-gradient(to right, #f94c4c, #ff5757);
        &:after {
            background-image: url(../img/oem/1.png);
        }
    }
    .oem1-2 {
        background: url(../img/oem/2_1.png) no-repeat right 40px bottom -25px, linear-gradient(to right, #3b7aec, #00a2e9);
        &:after {
            background-image: url(../img/oem/2.png);
        }
    }
    .oem1-3 {
        background: url(../img/oem/3_1.png) no-repeat right 40px bottom -25px, linear-gradient(to right, #2fb7d0, #5de89b);
        &:after {
            background-image: url(../img/oem/3.png);
        }
    }
    .oem1-4 {
        background: url(../img/oem/4_1.png) no-repeat right 40px bottom -25px, linear-gradient(to right, #ff6c38, #ff8454);
        &:after {
            background-image: url(../img/oem/4.png);
        }
    }

    .oem2-1 {
        background: url(../img/oem/5_1.png) no-repeat right 40px bottom -25px, linear-gradient(to right, #5d359f, #9766e7);
        &:after {
            background-image: url(../img/oem/5.png);
        }
    }
    .oem2-2 {
        background: url(../img/oem/6_1.png) no-repeat right 40px bottom -25px, linear-gradient(to right, #fb3a91, #fe7280);
        &:after {
            background-image: url(../img/oem/6.png);
        }
    }
    .oem2-3 {
        background: url(../img/oem/7_1.png) no-repeat right 40px bottom -25px, linear-gradient(to right, #9759c5, #c049d6);
        &:after {
            background-image: url(../img/oem/7.png);
        }
    }
    .oem2-4 {
        background: url(../img/oem/8_1.png) no-repeat right 40px bottom -25px, linear-gradient(to right, #9478e7, #716eee);
        &:after {
            background-image: url(../img/oem/8.png);
        }
    }
}

.slider_input {
    display: flex;
    align-items: center;
    height: 38px;
    .slider {
        width: 100%;
    }
    .layui-slider-bar {
        background-color: $color!important;
    }
    .layui-slider-wrap-btn {
        border-color: $color!important;
    }
}
