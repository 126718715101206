/****************************** components ******************************/
@import "../../../Public/source/css/yunu.scss"; // 引入公共模块
/****************************** style ******************************/
.login {
    background: url(../img/login_bg.jpg) no-repeat center;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .window {
        position: relative;
        .title,.copyright {
            position: absolute;
            left: 0;
            width: 100%;
            color: #fff;
            text-align: center;
        }
        .title {
            bottom: 100%;
            font-size: 30px;
            padding-bottom: 35px;
        }
        .copyright {
            top: 100%;
            font-size: 16px;
            padding-top: 10px;
        }
        .box {
            width: 500px;
            border-radius: 5px;
            background-color: #f5f1ef;
            padding: 45px 55px;
            overflow: hidden;
            box-shadow: 0 0 10px #aaa;
        }
        .input_group {
            position: relative;
            margin-bottom: 15px;
            height: 45px;
            input {
                width: 390px;
                height: 45px;
                line-height: 43px;
                border-radius: 5px;
                background-color: #fff;
                border: 1px solid #dbdad8;
                padding-left: 60px;
                padding-right: 10px;
                &:focus {
                    border-color: #32b2ea;
                    outline: 0;
                }
            }
            .icon {
                position: absolute;
                left: 0;
                top: 0;
                z-index: 9;
                width: 50px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .vcode {
            input {
                padding-right: 110px;
            }
        }
        .verify {
            position: absolute;
            right: 1px;
            top: 1px;
            z-index: 9;
            cursor: pointer;
        }
        .submit {
            width: 100%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            color: #fff;
            font-size: 18px;
            border: 0;
            border-radius: 5px;
            @include hover(#00a2e9,background-color);
        }
    }
}
