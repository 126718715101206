$color: #4381e6; // 默认主题颜色
$color2: #00a2e9;
$c_000: #000;
$c_666: #666;
$c_999: #999;
$c_fff: #fff;
$c_f00: #f00;
.color {color: $color}
.color2 {color: $color2}
.c_000 {color: $c_000}
.c_666 {color: $c_666}
.c_999 {color: $c_999}
.c_fff {color: $c_fff}
.c_f00 {color: $c_f00}

a {
    transition: color .2s;
    &:hover {
        color: darken($color, 5%);
    }
}
